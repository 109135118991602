module.exports = {
	"passability_monitors": {
		"new_monitor": "Создать монитор",
		"not_set": "–",
		"daily": {
			"groupings": "Группировки",
			"enabled": "Включен",
			"current_passability": "Текущая проходимость",
			"average_passability": "Средняя проходимость",
			"historical_values": "История проходимости",
			"threshold_minutes": "Трешхолд алерта",
			"new_monitor": "Создать новый монитор",
			"name": "Название",
			"days_counts": "Количество дней",
			"actions": "Действия",
			"edit": "Редактировать",
			"filters": "Фильтры",
			"updated": "Монитор успешно обновлен",
			"slice_updated": "Слайс успешно обновлен",
			"created": "Монитор успешно создан"
		}
	}
};
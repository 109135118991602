module.exports = {
	"passability_monitors": {
		"new_monitor": "Create Monitor",
		"not_set": "–",
		"daily": {
			"groupings": "Groupings",
			"enabled": "Enabled",
			"current_passability": "Current Passability",
			"average_passability": "Average Passability",
			"historical_values": "Historical Values",
			"threshold_minutes": "Alert Threshold",
			"new_monitor": "Create New Monitor",
			"name": "Name",
			"days_counts": "Days Count",
			"interval_minutes": "Interval",
			"actions": "Actions",
			"edit": "Edit",
			"filters": "Filters",
			"updated": "Monitor was successfully updated",
			"slice_updated": "Slice was successfully updated",
			"created": "Monitor was successfully created"
		}
	}
};